import React, { Component } from 'react';
import Filters from "./filters";
import SortBy from "./sort-by";
//import "./coupon-list.scss"
import "../../styles/scss/pages/category/coupon-list.scss"
import DayCoupon from "./day-coupon";
import UpcomingCoupon from "./upcoming-coupon";
import OtherCoupons from "./other-coupons";
// import ExpiredCoupons from "./expired-coupons";
import Pagination from "./pagination";
import OtherOffers from "./other-offers";
import Subscribe from "./subscribe";
import Faqs from "./faqs";
import AlgoliaSearch from "../../library/common/algolia-search";
import ArrayUtils from "../../utils/array";
import HcFunctions from "../../utils/hc-functions";
import DH from "../../utils/details-helpers";
import { Link } from 'gatsby';
const _ = require('lodash');

class CouponsList extends Component {

	constructor(props) {
		super(props);
		let self = this;
		let { pageTitle, pageTitleData } = this.props;
		let pageFilterKey = "";
		const pageType = (typeof self.props.pageType === "string") ? self.props.pageType : '';
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.mainFilter = props.mainFilter;
		this.isSearch = pageType == "Search";
		this.searchQuery = "";
		this.searchDefaultFacets = null;
		this.state = {
			page: 1,
			pageTitle: pageTitle,
			pageTitleData: pageTitleData,
			mainFilter: props.mainFilter || [],
			facets: props.offers.facets,
			offers: props.offers,
			selectedFacets: {}
		}
		switch (pageType.toLocaleLowerCase()) {
			case "category":
				pageFilterKey = AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME;
				break;
			case "bank":
				pageFilterKey = AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME;
				break;
			case "store":
				pageFilterKey = AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME;
				break;
			case "subcategory":
				pageFilterKey = AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME;
				break;
			case "search":
				pageFilterKey = AlgoliaSearch.SEARCH_FILTER_QUERY;
				break;
			case "list":
				pageFilterKey = [];
				if(Array.isArray(props.mainFilter)) {
					props.mainFilter.forEach(filter => {
						let key = filter.split(":")[0].trim();
						let value = filter.split(":")[1].trim();
						pageFilterKey.push(key);
						this.state.selectedFacets[key] = value;
					});
				}
				break;
		}
		self.pageFilterKey = pageFilterKey;
		if(pageType.toLocaleLowerCase() !== "list") {
			this.state.selectedFacets[pageFilterKey] = pageTitle;
		}
	}

	componentDidMount() {
		const self = this;
		if (self.isSearch && HcFunctions.isClient()) {
			const searchQuery = HcFunctions.getParameterByName("q");
			if (searchQuery) {
				self.searchQuery = searchQuery;
				self.onFilterChange(null, searchQuery);
			}
		}
	}

	scrollToTop() {
		const elmnt = document.getElementById("coupon-list");
		elmnt.scrollIntoView();
	}

	async onPageChange(page, isMobile) {
		const self = this;
		const algoliaSearch = new AlgoliaSearch();
		const fullFilter = self.mainFilter;
		const searchQuery = self.searchQuery || "";
		algoliaSearch
			.getDataByFilter(fullFilter, searchQuery, page)
			.then(filteredOffers => {
				let offers = filteredOffers;
				if(isMobile) {
					offers.data = self.state.offers.data.concat(filteredOffers.data);
				}
				self.setState(state => ({
					page: page+1,
					offers: offers,
				}));
			})
		if(!isMobile) {
			self.scrollToTop();
		}
	}

	async onFilterChange(filter, searchQuery) {
		const self = this;
		const { offers } = self.props;
		const pageType = (typeof self.props.pageType === "string") ? self.props.pageType : '';
		const algoliaSearch = new AlgoliaSearch();
		const fullFilter = filter || self.mainFilter;//self.state.mainFilter.concat(filter);
		let filterMapper = {};
		searchQuery = searchQuery || self.searchQuery || "";
		self.mainFilter = fullFilter;
		let categoryFilters = [];
		const validCategoryFscets = [
			AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME, 
			AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME
		];
		let validCards = [];
		ArrayUtils.flattenArray(fullFilter).forEach(a => {
			let key = a.split(":")[0].trim();
			if(key === AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE) {
				validCards.push(a.split(":")[1].trim());
			}
			let facetIndex = validCategoryFscets.indexOf(key);
			if(facetIndex > -1) {
				if(typeof categoryFilters[facetIndex] === "undefined") {
					categoryFilters[facetIndex] = [];
				} 
				categoryFilters[facetIndex].push(a);
			}
		})
		algoliaSearch
			.getDataByFilter(fullFilter, searchQuery)	
			.then(filteredOffers => {
				let selectedFacets = {};
				let pageTitle = self.state.pageTitle;
				let pageTitleData = self.state.pageTitleData;
				if(self.pageFilterKey === AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME) {
					pageTitleData = _.get(filteredOffers, 'data[0].offer.categories[0]');
				}
				ArrayUtils.flattenArray(fullFilter).forEach(a => {
					let key = a.split(":")[0];
					let value = a.split(":")[1].trim();
					if(
						key === AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE
					) {
						Object.keys(filteredOffers.facets[key]).forEach(card => {
							if(validCards.indexOf(card) === -1) {
								// remove unwanted facets form selected
								delete filteredOffers.facets[key][card];
							}
						})
					}
					if (filteredOffers.facets[key]) {
						let pageFilterKey = self.pageFilterKey;
						if (pageFilterKey) {
							let title = filteredOffers.facets[pageFilterKey];
							if (Array.isArray(title)) {
								title = title[0];
							}
							if (typeof title == "object") {
								title = Object.keys(title)[0];
							}
							pageTitle = title;
						}
						selectedFacets[key] = filteredOffers.facets[key];
					}
				})
				let setData = {
					pageTitle: pageTitle,
					pageTitleData: pageTitleData,
					selectedFacets: selectedFacets,
					offers: filteredOffers,
					page: 1,
				};
				if(self.isSearch) {
					if (!self.searchDefaultFacets) {
						self.searchDefaultFacets = {};
						if(filteredOffers.facets[AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME]) {
							self.searchDefaultFacets[AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME] = filteredOffers.facets[AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME];
						}
					}
					setData.facets = { ...filteredOffers.facets, ...self.searchDefaultFacets };
				}
				self.setState(state => (setData));
			})

		let filteredFacets = self.state.facets;

		let updateFilter = function (type, fullFilter) {
			algoliaSearch
				.getFacetByFilter(type, fullFilter, searchQuery)
				.then(resFacets => {
					filteredFacets[type] = resFacets;
					self.setState(state => ({
						facets: filteredFacets
					}));
				});
		};

		let actionList = [];
		if(!self.isSearch) {
			let keyList = Array.isArray(self.pageFilterKey)?self.pageFilterKey:[self.pageFilterKey];
			if(keyList.indexOf(AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME) === -1) {
				categoryFilters = fullFilter;
			}
			actionList = [
				updateFilter(AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME, categoryFilters),
				updateFilter(AlgoliaSearch.SEARCH_FILTER_OFFER_TYPE_NAME, fullFilter),
				updateFilter(AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE, fullFilter),
			];
			if(keyList.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME) === -1) {
				actionList.push(updateFilter(AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME, fullFilter))
			}
			if(keyList.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME) === -1) {
				actionList.push(updateFilter(AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME, fullFilter))
			}
			if(keyList.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME) === -1) {
				actionList.push(updateFilter(AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME, fullFilter))
			}
		}
		Promise.all(actionList).catch(e => {
			console.error("HC:Error onFilterChange", e);
		})
		self.scrollToTop();
	}

	render() {
		let self = this;
		let { other_offers, pageType, pageData } = this.props;
		let { facets, selectedFacets, offers, pageTitle, pageTitleData } = this.state;
		const isSearch = self.isSearch;
		offers = offers || {};
		pageTitleData = pageTitleData || {};
		if(isSearch) {
			pageTitle = self.searchQuery;
		}
		const pageIcon = pageTitleData.iconUrl || '';
		//Dijin : TODO : for now I'm breaking offers into 3 parts, Featured, DoD and regular, but probably we should do these queries togther on Algolia and join results
		let offersBreakUp = {'featured' : [], 'deals' : [], 'regular' : []};
		for (var o in offers.data) {
			let item = offers.data[o];
			if(item.offer.endDateTime_timestamp > Date.now()) {
				continue;
			}
			if ( DH.isDealOfTheDay(item.offer) ) { offersBreakUp['deals'].push(item); }
			else if ( item.offer.isFeatured ){ offersBreakUp['featured'].push(item); }
			else { offersBreakUp['regular'].push(item); }		
		}
		// console.log(offersBreakUp);
		
		let seo_filler = (pageType === "List" && pageData.list.cardTransactionType) ? pageData.list.cardTransactionType.toLowerCase() + " card" : "credit and debit card";
			
		let bankOtherOffers = [];
		let allListpages = pageData.allListpages;
		for (var i in allListpages) {
			if ((pageData.bank && allListpages[i].bank_id === pageData.bank.id) ||
				(pageData.store && allListpages[i].store_id === pageData.store.id) ||
				(pageData.list && pageData.list.bank && allListpages[i].bank_id === pageData.list.bank.id) ||
				(pageData.list && pageData.list.store && allListpages[i].store_id === pageData.list.store.id) )
			{
				bankOtherOffers.push(allListpages[i]);
			}
		}
		let others_h2 = [];
		if (pageData.bank){ others_h2.push(pageData.bank.name); }
		else if (pageData.list && pageData.list.bank){ others_h2.push(pageData.list.bank.name); }
		if (pageData.store){ others_h2.push(pageData.store.name); }
		else if (pageData.list && pageData.list.store){ others_h2.push(pageData.list.store.name); }

		return (
			<div>
				<div className="courpon-list" id="coupon-list">
					<h2 className={"travels" + " " + HcFunctions.slugify(pageTitle)}>
						{pageIcon ? <span className="icon-type"><img src={pageIcon} alt="Category Icon" /></span> : ''}
						{pageTitle && <span>{pageTitle + " - "}</span>} {offers.totalCount} {seo_filler} Offers
					</h2>
					{offers.totalCount > 0 &&
					<>
					<Filters
						pageFilterKey={self.pageFilterKey}
						filters={facets}
						selectedFacets={selectedFacets}
						onFilterChange={this.onFilterChange}
					/>
					<div className="content-wrapper">
						<SortBy
							pageFilterKey={self.pageFilterKey}
							filters={facets}
							selectedFacets={selectedFacets}
							onFilterChange={this.onFilterChange}
						/>
						{ (( offersBreakUp['featured'].length > 0) || (offersBreakUp['deals'].length > 0)) &&  
						<div className="container best-deals-coupon">
							{ offersBreakUp['featured'].length > 0 && <UpcomingCoupon offers={offersBreakUp['featured']} /> }
							{ offersBreakUp['deals'].length > 0 && <DayCoupon offers={offersBreakUp['deals']} pageType={pageType} />}
						</div>
						}
						<div className="container other-list">
							<OtherCoupons offers={offersBreakUp['regular']} pageType={pageType} />
							<Pagination 
								page={self.state.page}
								totalCount={_.get(self.state, "offers.totalCount")} 
								data={_.get(self.state, "offers")} 
								pageLimit={10}
								onPageChange={self.onPageChange}
							/>
						</div>
					</div>
					</>
					}
				</div>
				{ !isSearch &&
					<div className="other-info-list">
						<Subscribe />
						<Faqs page={pageData} />
						<OtherOffers offers={other_offers.tb} />
						<OtherOffers offers={other_offers.ts} />
						
										
				{bankOtherOffers.length > 0 &&
				<div className="faq-wrapper related-coupon-wrapper related-offer-list">
					<div className="container">
					<h2>Other {others_h2.join(", ")} Offers:</h2>
					<div className="all-list-wrapper">
						{bankOtherOffers.map(list => (
						<h3 key={list.id}> <Link to={list.url}>{list.h1}</Link></h3>
						))}
					</div>
					</div>
				</div>
				}

						
					</div>
				}
			</div>
		)
	}
}

export default CouponsList