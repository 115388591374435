import { Link } from "gatsby"
import React from "react"
import DH from "../../utils/details-helpers";

const UpcomingCoupon = ({ offers }) => {
	if(!Array.isArray(offers) || offers.length == 0) {
		return '';
	}

	return (Array.isArray(offers) && offers.length > 0 &&
	  <div>
		{offers.map(offer => {
			
			const hasBank = offer.offer.bank ? true : false;
			const hasCardBrand = !!offer.offer.cards[0].brand ? true : false;
			const hasStore = offer.offer.store ? true : false;
			const startString = DH.getFeaturedOfferTimerString(offer.offer);
			const offerH3 = DH.getFeaturedOfferString(offer.offer, "" )	
			
			return (
			<div key={offer.id}>
				<Link to={offer.gatsbyUrl} className="coupon-cart upcoming">
					<div className="coupon-head">
						<span className="deal">Featured Offer</span>
						<div className="end-date">
							<span>{startString}</span>
						</div>
					</div>
					<h3 dangerouslySetInnerHTML={{ __html: offerH3 }} />
					<div className="upcoming-logos">
					{hasBank && <img className="bnk-logo" src={offer.offer.bank.logoUrl} alt={offer.offer.bank.name} />}
					{!hasBank && hasCardBrand && <img className="bnk-logo" src={offer.offer.cards[0].brand.logoUrl} alt="All banks offer" />}
					{hasStore && <img className="bnk-logo" src={offer.offer.store.logoUrl} alt={offer.offer.store.name + " Offer"} />}
					</div>
				</Link>
			</div>
			)
		})}
	  </div>	
	)

}

export default UpcomingCoupon