import { Link } from "gatsby"
import React from "react"
import DH from "../../utils/details-helpers";

const OtherCoupons = ({ offers, pageType}) => {
	// console.log(offers);
	return (Array.isArray(offers) && offers.length > 0 &&
		<div>
		  <div>
			{offers.map(offer => {
			  
				const hasBank = offer.offer.bank ? true : false;
				const hasCardBrand = !!offer.offer.cards[0].brand ? true : false;
				const hasStore = offer.offer.store ? true : false;
				const offerTime = DH.getOfferTime(offer.offer.startDateTime_timestamp * 1000, offer.offer.endDateTime_timestamp * 1000);
				const aboutCard = DH.getAboutCardString(offer.offer);
				const cardType = DH.getCardTypeString(offer.offer);
				const validity = offerTime.startSet ? (offerTime.startTime + " - " + offerTime.endTime) : ("Till " + offerTime.endTime);
			  
				return <Link to={offer.gatsbyUrl} key={offer.id} className="coupon-cart others">
				<div className="left-clm">
				{hasBank && <img className="bnk-logo" src={offer.offer.bank.logoUrl} alt={offer.offer.bank.name + " Offer"} />}
				{!hasBank && hasCardBrand && <img className="bnk-logo" src={offer.offer.cards[0].brand.logoUrl} alt="All banks offer" />}
				{hasStore && <img className="bnk-logo" src={offer.offer.store.logoUrl} alt={offer.offer.store.name + " Offer"} />}
				</div>
				<div className="right-clm">
				  <h3>{offer.h1.replace(/&amp;/gi, "&")}</h3>
				  <div className="info">
					<p>
					  {hasStore && <span>By : <b>{offer.offer.store.name}</b></span>}
					  <span className="valid">Valid Till : <b>{validity}</b></span>
					  {hasBank && <span>Bank  : <b>{offer.offer.bank.name}</b></span>}
					  <span className="valid">Deal Type: <b>{offer.offer.type[0].name}</b></span>
					  <span className="valid">Card Type: <b>{cardType}</b></span>
					  { offer.offer.subCategoryL2s.length > 0 && <span className="valid">Segment: <b>{offer.offer.subCategoryL2s[0].name}</b></span> }
					</p>
					<p className="info-txt">
						{aboutCard}
					</p>
				  </div>
				</div>
				</Link>
			})}
		  </div>
		</div>
	)
}

export default OtherCoupons