import React from "react"
import "../../styles/scss/pages/category/banner.scss"
import Breadcrumbs from "../common/breadcrumbs";

const Banner = ( {page} ) => {
	let listData = page.listData;
	let month = new Date();
	month = month.toLocaleDateString("en-US", { day: 'numeric', month: 'long', year:'numeric' });

	return (
	  <div className="banner cat-banner">
		  <div className="container">
			<div className="banner-wrp">
			  <Breadcrumbs pageType={page.type} pageData={listData} pageUrl={page.url}/>
			  <h1>{ page.h1 + " [ " + month + " ]"} </h1>
			  <p>{page.about.text}</p>
				{((page.type === "Bank") || (page.type === "Store")) && <img className="bnk-logo" src={listData.logoUrl} alt={page.h1} /> }
				{(page.type === "List") && listData.bank && <img className="bnk-logo" src={listData.bank.logoUrl} alt={page.h1} /> }
				{(page.type === "List") && listData.store && <img className="bnk-logo" src={listData.store.logoUrl} alt={page.h1} /> }
			  </div>
				<img className="bg-logo" src={listData.bannerUrl} alt={listData.name + " banner"} />
				<img className="bg-logo only-mobile" src={listData.mobileBannerUrl} alt={listData.name + " mobile banner"} />
		  </div>
	  </div>
	)
}

export default Banner