exports.removeByValue = function(array, item) {
    var index = array.indexOf(item);
    if (index !== -1) array.splice(index, 1);
    return array;
}

exports.removeByIndex = function(array, index) {
    if (index > -1) array.splice(index, 1);
    return array;
}

exports.flattenArray = function(oldArray) {
    return Array.prototype.concat.apply([], oldArray);
}