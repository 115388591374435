import React, { Component } from 'react';
import AlgoliaSearch from "../../library/common/algolia-search";
import ArrayUtils from "../../utils/array";

class SortBy extends Component {
    constructor(props) {
        super(props)
        this.toggleSortFilter = this.toggleSortFilter.bind(this);
    }
    state = {
        showDropdown: false,
        selectedValues: [],
    }
    toggleSortFilter(e) {
        let currentDom = e.currentTarget;
        let listDom = document.getElementById(e.currentTarget.id + "List");
        let activeStatus = false;
        if (currentDom) {
            activeStatus = !currentDom.classList.contains("active");
        }
        let els = document.getElementsByClassName('filter-sort-list active');
        let fls = document.getElementsByClassName('filter-result active');
        if (els) {
            while (els[0]) {
                els[0].classList.remove('active');
                fls[0].classList.remove('active')
            }
        }
        if (currentDom && listDom) {
            if (activeStatus) {
                currentDom.classList.add("active");
                listDom.classList.add("active");
            } else {
                currentDom.classList.remove("active");
                listDom.classList.remove("active");
            }
        }

        /*
        let classes = 'filter-sort-list';
        //let showClsass = 'filter-result'
        let els = document.getElementsByClassName('filter-sort-list active');
        //let fls = document.getElementsByClassName('filter-result active');
        if (els) {
            while (els[0]) {
                els[0].classList.remove('active');
                //fls[0].classList.remove('active')
            }
        }
        e.target.className = classes.replace('filter-sort-list', 'filter-sort-list active');
        //e.target.className = showClsass.replace('filter-result','filter-result active');
        var one = document.getElementById("one");
        var two = document.getElementById("two");
        var three = document.getElementById("three");
        var oneCls = document.getElementById("oneList");
        var twoCls = document.getElementById("twoList");
        var threeCls = document.getElementById("threeList");

        if (one.className === "filter-sort-list active") {
            //alert("tererere");
            oneCls.classList.add("active");
        } else {
            oneCls.classList.remove("active");
        }
        if (two.className === "filter-sort-list active") {
            twoCls.classList.add("active");
        } else {
            twoCls.classList.remove("active");
        }
        if (three.className === "filter-sort-list active") {
            threeCls.classList.add("active");
        } else {
            threeCls.classList.remove("active");
        }
        */
    }
    toggleDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    selectFilter(e, type, valueType) {
        const self = this;
        if (typeof self.props.onFilterChange === "function") {
            let filters = [];
            let validFilters = ["store", "sub-categories", "banks", "categories", "cardType", "offerType"];
            if (self.props.pageFilterKey === AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME) {
                if (type === "categories") {
                    validFilters = ["categories", "banks"];
                }
            }
            validFilters.forEach(function (filterKey) {
                const dom = document.querySelectorAll(`input[data-filter='${filterKey}']:checked`);
                let key = '';
                switch (filterKey) {
                    case "sub-categories":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME;
                        break;
                    case "store":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME;
                        break;
                    case "categories":
                        key = AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME;
                        break;
                    case "banks":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME;
                        break;
                    case "cardType":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE;
                        break;
                    case "offerType":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_TYPE_NAME;
                        break;
                    default:
                        console.error("HC:Error invalid filter")
                        return;
                }
                let localFilter = [];
                dom.forEach(function (inputDom) {
                    let value = inputDom.getAttribute("id");
                    localFilter.push(key + ": " + value);
                })
                if (localFilter.length > 0) {
                    filters.push(localFilter);
                }
            })
            self.props.onFilterChange(filters);
        }
    }

    formatFacetToAarray(facet) {
        if (!facet) {
            return [];
        }
        if (Array.isArray(facet)) {
            return facet;
        }
        let arrayData = Object.keys(facet).map((key, i) => {
            return {
                count: facet[key],
                highlighted: key,
                value: key,
            };
        });
        return arrayData;
    }

    render() {
        const self = this;
        const LIMIT = 50;
        // const showDropdownActive = this.state.showDropdown ? 'active' : '';
        const { filters, selectedFacets } = this.props;
        let { pageFilterKey } = this.props;
        pageFilterKey = Array.isArray(pageFilterKey)?pageFilterKey:[pageFilterKey];
        const banks = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME]);
        const offerType = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_OFFER_TYPE_NAME]);
        const cardType = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE]);
        let selectedFullList = [];
        return (
            <div className="filter-wrapper-top">
                <div className="filter-mode">
                    <span 
                        className={pageFilterKey.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME) > -1 ? "hide" : "filter-sort-list"} 
                        id="one" 
                        onClick={(e) => this.toggleSortFilter(e)}
                    >Bank</span>
                    <span className="filter-sort-list" id="two" onClick={(e) => this.toggleSortFilter(e)}>Offer Type</span>
                    <span className="filter-sort-list" id="three" onClick={(e) => this.toggleSortFilter(e)}>Card Type</span>

                    {/* <a className="filter-sort-list" onClick={(e) =>this.toggleSortFilter(e)}>Card Brand</a> */}
                </div>
                <div className="filter-result" id="oneList">
                    {
                        (banks).map((field, i) => {
                            let className = (i > LIMIT - 1) ? "hide" : "";
                            let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME] || '';
                            if (typeof selectedValues !== "string") {
                                selectedValues = Object.keys(selectedValues);
                                if (pageFilterKey.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME) == -1 && selectedValues.indexOf(field.value) > -1) {
                                    selectedFullList = selectedFullList.concat(selectedValues);
                                }
                            }
                            return <div key={field.value}>
                                <input
                                    id={field.value}
                                    value={field.value}
                                    type="checkbox"
                                    className={className}
                                    data-filter="banks"
                                    defaultChecked={selectedValues.indexOf(field.value) > -1}
                                    onClick={(e) => {
                                        self.selectFilter(e, "banks");
                                    }}
                                />
                                <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                            </div>;
                        })
                    }
                </div>
                <div className="filter-result" id="twoList">
                    {
                        (offerType).map((field, i) => {
                            let className = (i > LIMIT - 1) ? "hide" : "";
                            let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_OFFER_TYPE_NAME] || '';
                            if (typeof selectedValues !== "string") {
                                selectedValues = Object.keys(selectedValues);
                                if (selectedValues.indexOf(field.value) > -1) {
                                    selectedFullList = selectedFullList.concat(selectedValues);
                                }
                            }
                            return <div key={field.value}>
                                <input
                                    id={field.value}
                                    value={field.value}
                                    type="checkbox"
                                    className={className}
                                    data-filter="offerType"
                                    defaultChecked={selectedValues.indexOf(field.value) > -1}
                                    onClick={(e) => {
                                        self.selectFilter(e, "offerType");
                                    }}
                                />
                                <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                            </div>;
                        })
                    }
                </div>
                <div className="filter-result" id="threeList">
                    {
                        (cardType).map((field, i) => {
                            let className = (i > LIMIT - 1) ? "hide" : "";
                            let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE] || '';
                            if (typeof selectedValues !== "string") {
                                selectedValues = Object.keys(selectedValues);
                                if (selectedValues.indexOf(field.value) > -1) {
                                    selectedFullList = selectedFullList.concat(selectedValues);
                                }
                            } else {
                                if(selectedValues) {
                                    selectedFullList = selectedFullList.concat(selectedValues);
                                }
                            }
                            return <div key={field.value}>
                                <input
                                    id={field.value}
                                    value={field.value}
                                    type="checkbox"
                                    className={className}
                                    data-filter="cardType"
                                    defaultChecked={selectedValues.indexOf(field.value) > -1}
                                    onClick={(e) => {
                                        self.selectFilter(e, "cardType");
                                    }}
                                />
                                <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                            </div>;
                        })
                    }
                </div>
                <div className="edit-filter">
                    {
                        [...new Set(selectedFullList)].map((item) =>
                            <span>
                                {item} 
                                <span onClick={(e) => {
                                    let dom = document.getElementById(item);
                                    if(dom) {
                                        dom.click();
                                    }
                                }}
                                >X</span>
                            </span>
                        )
                    }
                </div>
            </div>
        )
    }
};

export default SortBy