import { Link } from "gatsby"
import React from "react"

const OtherOffers = ({ offers }) => {
	return (
		<div className="container other-offers">
			<h2>{offers.title}</h2>
			<div className="logo-list">
				{offers.data.map(entity => {
					let offerCount = entity.count ? entity.count : entity.offers.length;
					let url = entity.page.gatsbyUrl || entity.page.url;
					return (
						<Link to={url} key={entity.id}>
							<span>
								<img className="bnk-logo" src={entity.logoUrl} alt={entity.name + " Offer"} />
								{offerCount} offers found
							</span>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

export default OtherOffers