const algoliasearch = require('algoliasearch');
const algoliaClient = algoliasearch('O25AEIWNWR', '24efe9662a1f3ed590a6e927aea52717');
const algoliaIndexName = "prod_happycredit_v1";
const HcFunctions = require("../../utils/hc-functions");

/* 
//Facets available
"searchable(offer.categories.name)",
"searchable(offer.subCategories.name)",
"searchable(offer.store.name)",
"searchable(offer.bank.name)",
"searchable(offer.type.name)", // Instant Dicount etc
"searchable(offer.cards.transactionType)", //Credit/Debit etc

//Filters available
"filterOnly(offer.isFeatured)",
"filterOnly(offer.dealOfTheDay)",
"filterOnly(offer.offlineOffer)",
"filterOnly(offer.startDateTime_timestamp)",
"filterOnly(offer.endDateTime_timestamp)",
"filterOnly(offer.days.iso)" // number from 1-7 for Monday-Sunday
*/

class AlgoliaSearch {

    constructor(index) {
        this.algoliaIndex = algoliaClient.initIndex(algoliaIndexName);
    }

    static get SEARCH_FILTER_CATEGORIES_NAME() { return "offer.categories.name"; }
    static get SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME() { return "offer.subCategories.name"; }
    static get SEARCH_FILTER_QUERY() { return "query"; }
    static get SEARCH_FILTER_OFFER_STORE_NAME() { return "offer.store.name"; }
    static get SEARCH_FILTER_OFFER_BANK_NAME() { return "offer.bank.name"; }
    static get SEARCH_FILTER_OFFER_TYPE_NAME() { return "offer.type.name"; }
    static get SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE() { return "offer.cards.transactionType"; }
    static get SEARCH_FILTER_DEAL_DAY() { return "offer.dealOfTheDay"; }
    static get PAGE_ITEM_LIMIT() { return 10; }

    async getDataByFilterNew({ filters, facetQuery, page, limit, operators }) {
        return this.getDataByFilter(filters, facetQuery, page, limit, operators)
    }

    async getDataByFilter(filters, facetQuery, page = 0, limit, operators =``) {
        if(isNaN(page)) {
            page = 0;
        }
        if(!Array.isArray(filters)) {
            filters = [];
        }
        if(typeof facetQuery !== "string") {
            facetQuery = "";
        }
        let response = await this.algoliaIndex.search({
            facets: "*",
            facetFilters: filters,
            page: page,
            query: facetQuery,
            hitsPerPage: limit || AlgoliaSearch.PAGE_ITEM_LIMIT,
            filters: operators,
        });
        return {
            data: (response && Array.isArray(response.hits)) ? response.hits : [],
            facets: response.facets,
            // full: response,
            totalCount: response.nbHits
        }
    }

    async getFacetByFilter(facetName, facetFilters, facetQuery) {
        let options = { facetName: facetName };
        let facetFiltersCopy = HcFunctions.clone(facetFilters);
        if(Array.isArray(facetFiltersCopy) && facetFiltersCopy.length >0 ) {
            let i;
            for(i in facetFiltersCopy) {
                let item = facetFiltersCopy[i];
                if(Array.isArray(item)) {
                    let isSameFacet = false;
                    item.forEach((value) => {
                        if(value.indexOf(facetName) > -1) {
                            isSameFacet = true;
                        }
                    })
                    if(isSameFacet) {
                        facetFiltersCopy.splice(i, 1);
                    }
                }
            }
            options.facetFilters = facetFiltersCopy;
        }
        options.facetQuery = facetQuery || "";
        let response = await this.algoliaIndex.searchForFacetValues(options);
        return (response && Array.isArray(response.facetHits)) ? response.facetHits : [];
    }
};

module.exports = AlgoliaSearch;