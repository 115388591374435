import { Link } from "gatsby"
import React, { Component } from 'react';
import HcFunctions from "../../utils/hc-functions";
import Helmet from "react-helmet"

class Pagination extends Component {

    constructor(props) {
        super(props);
        const self = this;
        self.state = {
            page: self.props.page || 1,
        }
        if(HcFunctions.isClient() && document.querySelector('link[rel="canonical"]')) {
            self.canonical = document.querySelector('link[rel="canonical"]').href;
        }
    }

    componentDidMount() {
        const self = this;
        if (HcFunctions.isClient()) {
            const page = parseInt(HcFunctions.getParameterByName("page"));
            if(!isNaN(page))
            self.selectPage(null, page);
        }
    }

    selectPage(e, page, isMobile) {
        const self = this;
        const { onPageChange } = self.props;
        onPageChange(page - 1, isMobile);
        e && e.preventDefault();
    }

    render() {
        const self = this, LIMIT = 10, PAGE_LIMIT = 20;
        const HALF = LIMIT/2;
        const { totalCount, pageLimit, page } = self.props;
        let totalPage = Math.ceil(totalCount / pageLimit);
        if(totalPage > 20) {
            totalPage = PAGE_LIMIT;
        }

        if (isNaN(page) || isNaN(totalCount) || isNaN(pageLimit)) {
            return '';
        }
        if(totalCount < pageLimit) {
            return '';
        }
        let start = 1;
        let end = totalPage;
        let hasDot = false;
        if(end > LIMIT) {
            hasDot = true;
            end = page+HALF;
            start = page-HALF;
            if(end<LIMIT) {
                end = LIMIT;
            }
            if(end>totalPage) {
                end=totalPage;
                start=end-LIMIT;
            }
            if(start<1) {
                start = 1;
            }
        }

        let pageList = [];
        if(start > 1) {
            pageList.push(<span>...</span>);
        }
        for (let i = start; i <= end; i++) {
            pageList.push(<a
                href={"?page=" + i}
                className={page == i ? "active" : ""}
                onClick={(e) => { self.selectPage(e, i) }}
				key={i}
            >{i}</a>);
        }
        if(end < totalPage) {
            pageList.push(<span>... </span>);
        }

        let seoData = "";
		
		/*
		//this is not needed as prev/next are obsolete and we want same canonical for all pages, which auto comes from S3 static files
        if (self.canonical && end > 1) {
            let canonical = (page == 1) ? self.canonical : self.canonical + "?page=" + page;
            let prev = (page == 1) ? "" : self.canonical + "?page=" + (page - 1);
            let next = (page == end) ? "" : self.canonical + "?page=" + (page + 1);
            seoData = (
                <Helmet>
                    {canonical && <link rel="canonical" href={canonical} />}
                    {prev && <link rel="prev" href={prev} />}
                    {next && <link rel="next" href={next} />}
                </Helmet>
            );
        }
		*/

        return (<>
            {/* seoData */}
            <div className="pagination">
                <div className="page-count">
                    Page {page} of {totalPage}
                </div>
                <div className="count-list">
                    <a
                        href={"?page=" + (page - 1)}
                        className={(page == 1 ? "disabled hide" : "arrow count-left")}
                        onClick={(e) => { self.selectPage(e, page - 1) }}
                    >Prev</a>
                    {pageList}
                    <a
                        href={"?page=" + (page + 1)}
                        className={(page == end ? "disabled hide" : "arrow count-right")}
                        onClick={(e) => { self.selectPage(e, page + 1) }}
                    >Next</a>
                </div>
            </div>
            <span 
                className={(page == end ? "disabled hide" : "load-more")}
                onClick={(e) => { self.selectPage(e, page + 1, true) }}
            >More Results</span>
        </>)
    }
}
export default Pagination