import React, { Component } from 'react';
import { Link } from "gatsby"
import AlgoliaSearch from "../../library/common/algolia-search";

class Filters extends Component {
    constructor(props) {
        super(props)
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.showAll = this.showAll.bind(this);
        this.state = {
            filters: [],
        }
    }
    state = {
        showSort: false,
        showFilter: false,
    }
    showAll(e, placeHolder) {
        e.preventDefault();
        let hiddenList = document.querySelectorAll(`#${placeHolder} .hide`);
        if (!hiddenList) return;
        hiddenList.forEach(dom => {
            dom.classList.remove("hide")
        });
        e.currentTarget.classList.add("hide")
    }
    toggleAccordion(e) {
        let classes = 'acrd-list';
        let els = document.getElementsByClassName('acrd-list active');
        if (els) {
            while (els[0]) {
                els[0].classList.remove('active')
            }
        }
        e.target.className = classes.replace('acrd-list', 'acrd-list active');
    }
    toggleSort = () => {
        this.setState({
            showSort: !this.state.showSort
        })
    }
    toggleFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    selectFilter(e, type, valueType) {
        const self = this;
        if (typeof self.props.onFilterChange === "function") {
            let filters = [];
            const allFilters = ["store", "sub-categories", "banks", "categories", "cardType", "offerType"];
            let validFilters = allFilters;
            if (self.props.pageFilterKey === AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME) {
                if (type === "categories") {
                    validFilters = ["categories", "banks"];
                }
            }
            if (self.props.pageFilterKey === AlgoliaSearch.SEARCH_FILTER_QUERY) {
                if (type === "categories") {
                    validFilters = ["categories", "banks"];
                }
            }
            if(type === "reset") {
                e && e.preventDefault();
                let pageFilters = Array.isArray(self.props.pageFilterKey)?self.props.pageFilterKey:[self.props.pageFilterKey];
                validFilters = [];
                pageFilters.forEach(function(key) {
                    switch (key) {
                        case AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME:
                            validFilters.push("sub-categories");
                            break;
                        case AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME:
                            validFilters.push("store");
                            break;
                        case AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME:
                            validFilters.push("banks");
                            break;
                        case AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME:
                            validFilters.push("categories");
                            break;
                    }
                })
            }
            validFilters.forEach(function (filterKey) {
                const dom = document.querySelectorAll(`input[data-filter='${filterKey}']:checked`);
                let key = '';
                switch (filterKey) {
                    case "sub-categories":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME;
                        break;
                    case "store":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME;
                        break;
                    case "categories":
                        key = AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME;
                        break;
                    case "banks":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_BANK_NAME;
                        break;
                    case "cardType":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_CARDS_TRANSACTION_TYPE;
                        break;
                    case "offerType":
                        key = AlgoliaSearch.SEARCH_FILTER_OFFER_TYPE_NAME;
                        break;
                    default:
                        console.error("HC:Error invalid filter")
                        return;
                }
                let localFilter = [];
                dom.forEach(function (inputDom) {
                    let value = inputDom.getAttribute("id");
                    localFilter.push(key + ": " + value);
                })
                if (localFilter.length > 0) {
                    filters.push(localFilter);
                }
            })
            if (type === "reset") {
                allFilters.forEach(function (filterKey) {
                    if (validFilters.indexOf(filterKey) > -1) {
                        return;
                    }
                    document
                        .querySelectorAll(`input[data-filter='${filterKey}']:checked`)
                        .forEach(function (inputDom) {
                            inputDom.checked =false;
                        })
                })
            }
            self.props.onFilterChange(filters);
        }
    }

    formatFacetToAarray(facet) {
        if(!facet) {
            return [];
        }
        if(Array.isArray(facet)) {
            return facet;
        }
        let arrayData = Object.keys(facet).map((key, i) => {
            return {
                count: facet[key],
                highlighted: key,
                value: key,
            };
        });
        return arrayData;
    }

    render() {
        const self = this;
        const sortMenuActive = this.state.showSort ? 'is-active' : '';
        const showFilterActive = this.state.showFilter ? 'is-active' : '';
        const { filters, selectedFacets } = this.props;
        let { pageFilterKey } = this.props;
        if (!Array.isArray(pageFilterKey)) {
            pageFilterKey = [pageFilterKey];
        }
        const LIMIT = 7;
        const categories = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME]);
        const subCategories = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME]) || [];
        const stores = self.formatFacetToAarray(filters[AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME]) || {};
        const storeClass = (pageFilterKey.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME) > -1) ? "hide" : "";
        const categoryClass = (pageFilterKey.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME) > -1) ? "hide" : "";
        const subCategoryClass = (pageFilterKey.indexOf(AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME) > -1) ? "hide" : "";
        return (
            <div className="sticky-filter-menu">
                <div className={`sort-overly ${sortMenuActive}`} onClick={this.toggleSort}></div>
                <div className={`filter-wrapper ${showFilterActive}`}>
                    <div className="filters-main">
                        <div className="head">
                            <span className="close" onClick={this.toggleFilter}></span>
                            <span className="title">Filters</span>
                            {/* <span className="top-clear">Clear All</span> */}
                            <Link to="#" className="clear"
                                onClick={(e) => {
                                    self.selectFilter(e, "reset");
                                }}
                            >Clear All</Link>
                        </div>
                        <div className="list">
                            <h3 className={"acrd-list active " + categoryClass} onClick={(e) => this.toggleAccordion(e)}>Category</h3>
                            <div className={"radios "+categoryClass}>
                            {
                                (categories).map((field, i) => {
                                    let className = (i > LIMIT - 1) ? "hide" : "";
                                    let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_CATEGORIES_NAME] || '';
                                    if(typeof selectedValues !== "string") {
                                        selectedValues = Object.keys(selectedValues);
                                    }
                                    return <div key={field.value}>
                                        <input 
                                            name="categories" 
                                            id={field.value} 
                                            value={field.value} 
                                            type="radio" 
                                            className={className}
                                            data-filter="categories" 
                                            defaultChecked={selectedValues.indexOf(field.value)>-1}
                                            onClick={(e) => {
                                                self.selectFilter(e, "categories");
                                            }}
                                        />
                                        <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                                    </div>
                                })
                            }
                            </div>
                            <h3 className={subCategoryClass + " acrd-list"} onClick={(e) => this.toggleAccordion(e)}>Sub-Categories</h3>
                            <div className={subCategoryClass + " checkboxes"}>
                                {
                                    (subCategories).map((field, i) => {
                                        let className = (i>LIMIT-1)?"hide":"";
                                        let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_OFFER_SUBCATEGORIES_NAME] || {};
                                        if(typeof selectedValues !== "string") {
                                            selectedValues = Object.keys(selectedValues);
                                        }
                                        return <div key={field.value}>
                                            <input id={field.value} type="checkbox" className={className}
                                                data-filter="sub-categories" 
                                                defaultChecked={selectedValues.indexOf(field.value)>-1}
                                                onClick={(e) => {
                                                    self.selectFilter(e, "sub-categories");
                                                }}
                                            />
                                            <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                                        </div>
                                    })
                                }
                            </div>
                            <h3 className={"acrd-list " + storeClass} onClick={(e) => this.toggleAccordion(e)}>Popular Stores</h3>
                            <div className={"checkboxes " + storeClass} id="store-filter">
                                {
                                    (stores).map((field, i) => {
                                        let className = (i>LIMIT-1)?"hide":"";
                                        let selectedValues = selectedFacets[AlgoliaSearch.SEARCH_FILTER_OFFER_STORE_NAME] || {};
                                        if (typeof selectedValues !== "string") {
                                            selectedValues = Object.keys(selectedValues);
                                        }
                                        return <div key={field.value}>
                                            <input id={field.value} type="checkbox" className={className} 
                                                data-filter="store" 
                                                defaultChecked={selectedValues.indexOf(field.value)>-1}
                                                onClick={(e) => {
                                                    self.selectFilter(e, "store");
                                                }}
                                            />
                                            <label className={className} htmlFor={field.value}>{field.value}<span>({field.count})</span></label>
                                        </div>;
                                    })
                                }
                                {
                                    (Object.keys(stores).length-LIMIT>0)?<Link to="#" onClick={(e) => { this.showAll(e, "store-filter") }} className="read-more">+{Object.keys(stores).length-LIMIT} more</Link>:''
                                }
                            </div>
                        </div>
                        <div className="filter-footer">
                            {/* <span className="filter-cls" onClick={this.toggleFilter}>Cancel</span>
                            <span className="filter-cls">Apply</span> */}
                            <span className="filter-cls" onClick={this.toggleFilter}>Close</span>
                        </div>
                    </div>
                </div>
                <div className="filter-footer">
                    {/* <span className="filter-cls" onClick={this.toggleSort}>SORT</span> */}
                    <span className="filter-cls" onClick={this.toggleFilter}>FILTER</span>
                </div>
            </div>
        )
    }
};
export default Filters