import React from 'react';
import Layout from '../components/layout';
import Banner from '../components/category/banner';
import CouponsList from '../components/category/couponsList';
import Helmet from "react-helmet"
import DH from "../utils/details-helpers";
const _ = require('lodash');

const CategoryPage = ({pageContext}) => {
	const pageType = pageContext.pageData.type;
	const offers_search_data = pageContext.pageData.searchData.offers.data.slice(0, 4);
	return (
	<>	
		{offers_search_data.map((search_offer,i) => {
			return (	
				<Helmet key={i}>
					<script type="application/ld+json">{DH.getOfferSaleEventSchema(search_offer, pageContext.pageData.url)}</script>
				</Helmet>
			)
		})}
		<Layout page={pageContext.pageData}>
			{
				pageType == "Search" && <div className="search-page"></div>
			}
			{
				pageType != "Search" && <Banner page={pageContext.pageData} />
			}
			<CouponsList 
				other_offers={pageContext.pageData.otherOffers} 
				offers={pageContext.pageData.searchData.offers} 
				pageType={pageType} 
				pageTitle={_.get(pageContext, "pageData.listData.name")}
				pageTitleData={pageContext.pageData.listData}
				mainFilter={pageContext.pageData.searchData.mainFilter} 
				pageData={pageContext.pageData} 
			/>
		</Layout>
	</>
	);
}

export default CategoryPage;